import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./Select.css";

const SelectedRecordPage = ({ selectedRecord, onClose }) => {
  return (
    <div className="modal-overlays">
      <div className="modal-contents">
        <div className="modal-headers">
          <CloseIcon className="close-icons" onClick={onClose} />
        </div>
        <div className="record-container">
          <div className="record-details-left">
            <div className="record-item">
              <span className="record-label">ID:</span>
              <span className="record-data">{selectedRecord.id}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Status:</span>
              <span
                className={`record-data ${selectedRecord.status === "Active" ? "active-status" : ""}`}
              >
                {selectedRecord.status}
              </span>
            </div>
            <div className="record-item">
              <span className="record-label">Full Name:</span>
              <span className="record-data">{selectedRecord.fullName}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Email:</span>
              <span className="record-data">{selectedRecord.email}</span>
            </div>
            <div className="record-item">
              <span className="record-label">DOB:</span>
              <span className="record-data">{selectedRecord.dob}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Joining Date:</span>
              <span className="record-data">{selectedRecord.joining}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Leaving Date:</span>
              <span className="record-data">{selectedRecord.leavingDate}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Department:</span>
              <span className="record-data">{selectedRecord.department}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Job Title:</span>
              <span className="record-data">{selectedRecord.tittle}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Bank:</span>
              <span className="record-data">{selectedRecord.bankname}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Account Number:</span>
              <span className="record-data">{selectedRecord.account}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Salary:</span>
              <span className="record-data">{selectedRecord.salary}</span>
            </div>
            <div className="record-item">
              <span className="record-label">Contact:</span>
              <span className="record-data">{selectedRecord.contact}</span>
            </div>
            <div className="record-item">
              <span className="record-label">City:</span>
              <span className="record-data">{selectedRecord.addressCity}</span>
            </div>
            
            <div className="record-item">
              <span className="record-label">CNIC:</span>
              <span className="record-data">
                {`${selectedRecord.cnic.slice(0, 5)}-${selectedRecord.cnic.slice(5, 12)}-${selectedRecord.cnic.slice(12, 13)}`}
              </span>
            </div>
            <div className="record-item">
              <span className="record-label">Gender:</span>
              <span className="record-data">{selectedRecord.gender}</span>
            </div>
          </div>
          <div className="record-details-right">
            <img
              src={`http://65.0.12.194:3000/uploads/${selectedRecord.profilePic}`}
              alt="Profile"
              className="profile-pictures"
            />
          </div>
        </div>
      </div>
    </div>
  );
};  
export default SelectedRecordPage;
