import React, { useState } from "react";
import { useProgressContext } from "../../ProgressContext";
import axios from "axios";
import "./Progress.css";
   
const Progress = () => {
  const { addProgressDetail } = useProgressContext();
  const [progressFormData, setProgressFormData] = useState({
    // googlenewDate: "", 
    googleAccount: "",
    googleTotalDownload1: "",
    googleMonthlyDownload1: "",
    googleDailyDownload1: "", 
    googleTotalDownload2: "",
    googleMonthlyDownload2: "",
    googleDailyDownload2: "",
    googleTotalDownload3: "",
    googleMonthlyDownload3: "",
    googleDailyDownload3: "",
    googleTotalDownload4: "",
    googleMonthlyDownload4: "",
    googleDailyDownload4: "",
    googleTotalDownload5: "",
    googleMonthlyDownload5: "",
    googleDailyDownload5: "",
    googleTotalDownload6: "",
    googleMonthlyDownload6: "",
    googleDailyDownload6: "",
    googleTotalDownload7: "",
    googleMonthlyDownload7: "",
    googleDailyDownload7: "",
    googleTotalDownload8: "",
    googleMonthlyDownload8: "",
    googleDailyDownload8: "",
    googleTotalDownload9: "",
    googleMonthlyDownload9: "",
    googleDailyDownload9: "",
    googleTotalDownload10: "",
    googleMonthlyDownload10: "",
    googleDailyDownload10: "",
    googleTotalDownload11: "",
    googleMonthlyDownload11: "",
    googleDailyDownload11: "",
    googleTotalDownload12: "",
    googleMonthlyDownload12: "",
    googleDailyDownload12: "",    
    googleApps: "",
    googleappPublishedDate: "", 
    gameAccount: "",
    gameTotalDownload1: "",
    gameMonthlyDownload1: "",
    gameDailyDownload1: "", 
    gameTotalDownload2: "",
    gameMonthlyDownload2: "",
    gameDailyDownload2: "",
    gameTotalDownload3: "",
    gameMonthlyDownload3: "",
    gameDailyDownload3: "",
    gameTotalDownload4: "",
    gameMonthlyDownload4: "",
    gameDailyDownload4: "",
    gameTotalDownload5: "",
    gameMonthlyDownload5: "",
    gameDailyDownload5: "",
    gameTotalDownload6: "",
    gameMonthlyDownload6: "",
    gameDailyDownload6: "",
    gameTotalDownload7: "",
    gameMonthlyDownload7: "",
    gameDailyDownload7: "",
    gameTotalDownload8: "",
    gameMonthlyDownload8: "",
    gameDailyDownload8: "",
    gameTotalDownload9: "",
    gameMonthlyDownload9: "",
    gameDailyDownload9: "",
    gameTotalDownload10: "",
    gameMonthlyDownload10: "",
    gameDailyDownload10: "",
    gameTotalDownload11: "",
    gameMonthlyDownload11: "",
    gameDailyDownload11: "",
    gameTotalDownload12: "",
    gameMonthlyDownload12: "",
    gameDailyDownload12: "",     
    gameApps: "",
    gameappPublishedDate: "", 
    iOSAccount: "",
iOSTotalDownload1: "",
iOSMonthlyDownload1: "",
iOSDailyDownload1: "",
iOSTotalDownload2: "",
iOSMonthlyDownload2: "",
iOSDailyDownload2: "",
iOSTotalDownload3: "",
iOSMonthlyDownload3: "",
iOSDailyDownload3: "",
iOSTotalDownload4: "",
iOSMonthlyDownload4: "",
iOSDailyDownload4: "",
iOSTotalDownload5: "",
iOSMonthlyDownload5: "",
iOSDailyDownload5: "",
iOSTotalDownload6: "",
iOSMonthlyDownload6: "",
iOSDailyDownload6: "",
iOSTotalDownload7: "",
iOSMonthlyDownload7: "",
iOSDailyDownload7: "",
iOSTotalDownload8: "",
iOSMonthlyDownload8: "",
iOSDailyDownload8: "",
iOSTotalDownload9: "",
iOSMonthlyDownload9: "",
iOSDailyDownload9: "",
iOSTotalDownload10: "",
iOSMonthlyDownload10: "",
iOSDailyDownload10: "",
iOSTotalDownload11: "",
iOSMonthlyDownload11: "",
iOSDailyDownload11: "",
iOSTotalDownload12: "",
iOSMonthlyDownload12: "",
iOSDailyDownload12: "",
    iOSApps: "",
    iOSappPublishedDate: "" ,
  });

  const appOptions = {
      "Appstark Tech": [
      "Number location",
      "Phone locator",
      "Smart switch- Data transfer",
      "Caller ID: Phone number lookup",
      "Data Recovery: Recover Files",
      "Cloud storage: Cloud backup",
      "FM Radio: radio tuner",
      "GPS Navigation- GPS Maps",
    ],
   
    
    "D-apps Studio": [
    "Mobile Number Locator",
    "PDF Scanner: PDF Creator",
    "Gallery App: Vault, album",
    "Cast to TV: Mirror Screen",
    "Wifi Scanner: wifi analyzer",
    "screen locker: time passcode",
    "Sticker Maker: Create Stickers",
    "Video to Mp3 converter",
    "Smart Switch Phone Transfer",
    "Speed camera: radar, alerts",
    "Video Recovery- Data recovery",
    "AI Photo Generator: AI Art",
    "Flight Radar- Airlines Tracker",
     ],
    
    "ActionShore": ["Ludo multiplayer Games- Dice",
  ],
    "Adnan Haider (ios Account)": ["Smart switch: clone phone",
    "AI Photo generator: AI Art",
    ],
    "Syed Kamran Haider (ios Account)": ["Cloud storage: Cloud backup",
    "Smart Switch- Mobile transfer",    
    ],
  };
  const handleProgressInputChange = (e) => {
    const { name, value } = e.target;
    setProgressFormData({
      ...progressFormData,
      [name]: value,
    });
  };

  const handleProgressSubmit = async (e) => {
    e.preventDefault();
    console.log("Progress Form submitted:", progressFormData);
    try {
      const response = await axios.post(
        "http://65.0.12.194:3000/progress",
        progressFormData
      );
      console.log(response.data);
      addProgressDetail(response.data);
    } catch (error) {
      console.error("Error creating progress:", error);
    }
    setProgressFormData({
      googleAccount: "",
      googleTotalDownload1: "",
      googleMonthlyDownload1: "",
      googleDailyDownload1: "", 
      googleTotalDownload2: "",
      googleMonthlyDownload2: "",
      googleDailyDownload2: "",
      googleTotalDownload3: "",
      googleMonthlyDownload3: "",
      googleDailyDownload3: "",
      googleTotalDownload4: "",
      googleMonthlyDownload4: "",
      googleDailyDownload4: "",
      googleTotalDownload5: "",
      googleMonthlyDownload5: "",
      googleDailyDownload5: "",
      googleTotalDownload6: "",
      googleMonthlyDownload6: "",
      googleDailyDownload6: "",
      googleTotalDownload7: "",
      googleMonthlyDownload7: "",
      googleDailyDownload7: "",
      googleTotalDownload8: "",
      googleMonthlyDownload8: "",
      googleDailyDownload8: "",
      googleTotalDownload9: "",
      googleMonthlyDownload9: "",
      googleDailyDownload9: "",
      googleTotalDownload10: "",
      googleMonthlyDownload10: "",
      googleDailyDownload10: "",
      googleTotalDownload11: "",
      googleMonthlyDownload11: "",
      googleDailyDownload11: "",
      googleTotalDownload12: "",
      googleMonthlyDownload12: "",
      googleDailyDownload12: "",    
      googleApps: "",
      googleappPublishedDate: "", 
      gameAccount: "",
      gameTotalDownload1: "",
      gameMonthlyDownload1: "",
      gameDailyDownload1: "", 
      gameTotalDownload2: "",
      gameMonthlyDownload2: "",
      gameDailyDownload2: "",
      gameTotalDownload3: "",
      gameMonthlyDownload3: "",
      gameDailyDownload3: "",
      gameTotalDownload4: "",
      gameMonthlyDownload4: "",
      gameDailyDownload4: "",
      gameTotalDownload5: "",
      gameMonthlyDownload5: "",
      gameDailyDownload5: "",
      gameTotalDownload6: "",
      gameMonthlyDownload6: "",
      gameDailyDownload6: "",
      gameTotalDownload7: "",
      gameMonthlyDownload7: "",
      gameDailyDownload7: "",
      gameTotalDownload8: "",
      gameMonthlyDownload8: "",
      gameDailyDownload8: "",
      gameTotalDownload9: "",
      gameMonthlyDownload9: "",
      gameDailyDownload9: "",
      gameTotalDownload10: "",
      gameMonthlyDownload10: "",
      gameDailyDownload10: "",
      gameTotalDownload11: "",
      gameMonthlyDownload11: "",
      gameDailyDownload11: "",
      gameTotalDownload12: "",
      gameMonthlyDownload12: "",
      gameDailyDownload12: "",     
      gameApps: "",
      gameappPublishedDate: "", 
      iOSAccount: "",
  iOSTotalDownload1: "",
  iOSMonthlyDownload1: "",
  iOSDailyDownload1: "",
  iOSTotalDownload2: "",
  iOSMonthlyDownload2: "",
  iOSDailyDownload2: "",
  iOSTotalDownload3: "",
  iOSMonthlyDownload3: "",
  iOSDailyDownload3: "",
  iOSTotalDownload4: "",
  iOSMonthlyDownload4: "",
  iOSDailyDownload4: "",
  iOSTotalDownload5: "",
  iOSMonthlyDownload5: "",
  iOSDailyDownload5: "",
  iOSTotalDownload6: "",
  iOSMonthlyDownload6: "",
  iOSDailyDownload6: "",
  iOSTotalDownload7: "",
  iOSMonthlyDownload7: "",
  iOSDailyDownload7: "",
  iOSTotalDownload8: "",
  iOSMonthlyDownload8: "",
  iOSDailyDownload8: "",
  iOSTotalDownload9: "",
  iOSMonthlyDownload9: "",
  iOSDailyDownload9: "",
  iOSTotalDownload10: "",
  iOSMonthlyDownload10: "",
  iOSDailyDownload10: "",
  iOSTotalDownload11: "",
  iOSMonthlyDownload11: "",
  iOSDailyDownload11: "",
  iOSTotalDownload12: "",
  iOSMonthlyDownload12: "",
  iOSDailyDownload12: "",
      iOSApps: "",
      iOSappPublishedDate: "" ,
    });
  };
  return (
    <div>
      <div className="header">
        <h1>App Progress</h1>
      </div>
      <div className="progress-detail">
        <h2 style={{ color: "#141414" }}> </h2>
        <div className="button-row">
          <form onSubmit={handleProgressSubmit}>
          {/* <div className="form-row">
        <div className="input-group">
          <label>
            New Date (Month and Year):
            <input
              type="month"
              name="googlenewDate"
              value={progressFormData.googlenewDate}
              onChange={handleProgressInputChange}
            />
          </label>
        </div>
      </div> */}
            <div className="form-row">
              <div className="input-group">
                <label>
                  Google Console:
                  <input
                    name="googleAccount"
                    value={progressFormData.googleAccount}
                    onChange={(e) => handleProgressInputChange(e)}
                    list="googleAccountList"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="googleAccountList">
                    <option value="">Select a Google Account</option>
                    <option value="Appstark Tech">Appstark Tech</option>
                    <option value="D-apps Studio">D-apps Studio</option>
                  </datalist>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <label>
                  Apps:
                  <input
                    name="googleApps"
                    value={progressFormData.googleApps}
                    onChange={handleProgressInputChange}
                    list="appsDatalist"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="appsDatalist"></datalist>
                </label>
                </div>
                </div>
                <div className="form-row">
              <div className="input-group">
                <label>
                  App Published Date:
                  <input
                    type="date"
                    name="googleappPublishedDate"
                    value={progressFormData.googleappPublishedDate}
                    onChange={handleProgressInputChange}
                  />
                </label>
              </div>
            </div>
            <div className="form-row">
  <div className="input-group">
    <label>
      Total Download 
      <input
        type="text"
        name="googleTotalDownload1"
        value={progressFormData.googleTotalDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="googleMonthlyDownload1"
        value={progressFormData.googleMonthlyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download       
      <input
        type="text"
        name="googleDailyDownload1"
        value={progressFormData.googleDailyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download     
      <input
        type="text"
        name="googleTotalDownload2"
        value={progressFormData.googleTotalDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload2"
        value={progressFormData.googleMonthlyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload2"
        value={progressFormData.googleDailyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload3"
        value={progressFormData.googleTotalDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload3"
        value={progressFormData.googleMonthlyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload3"
        value={progressFormData.googleDailyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload4"
        value={progressFormData.googleTotalDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload4"
        value={progressFormData.googleMonthlyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload4"
        value={progressFormData.googleDailyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload5"
        value={progressFormData.googleTotalDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload5"
        value={progressFormData.googleMonthlyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload5"
        value={progressFormData.googleDailyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload6"
        value={progressFormData.googleTotalDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="googleMonthlyDownload6"
        value={progressFormData.googleMonthlyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload6"
        value={progressFormData.googleDailyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download       
      <input
        type="text"
        name="googleTotalDownload7"
        value={progressFormData.googleTotalDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="googleMonthlyDownload7"
        value={progressFormData.googleMonthlyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload7"
        value={progressFormData.googleDailyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload8"
        value={progressFormData.googleTotalDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload8"
        value={progressFormData.googleMonthlyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload8"
        value={progressFormData.googleDailyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload9"
        value={progressFormData.googleTotalDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload9"
        value={progressFormData.googleMonthlyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload9"
        value={progressFormData.googleDailyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload10"
        value={progressFormData.googleTotalDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload10"
        value={progressFormData.googleMonthlyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload10"
        value={progressFormData.googleDailyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  </div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload11"
        value={progressFormData.googleTotalDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload11"
        value={progressFormData.googleMonthlyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload11"
        value={progressFormData.googleDailyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="googleTotalDownload12"
        value={progressFormData.googleTotalDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="googleMonthlyDownload12"
        value={progressFormData.googleMonthlyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="googleDailyDownload12"
        value={progressFormData.googleDailyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>
               <div className="form-row">
               <div className="input-group">
                <label>
                  Game Console:
                  <input
                    name="gameAccount"
                    value={progressFormData.gameAccount}
                    onChange={handleProgressInputChange}
                    list="GameAccountList"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="GameAccountList">
                    <option value="">Select a Game Account</option>
                    <option value="ActionShore">ActionShore</option>
                    
                  </datalist>
                </label>
                </div>
                </div>
                <div className="form-row">
                <div className="input-group">
                <label>
                  Apps:
                  <input
                    name="gameApps"
                    value={progressFormData.gameApps}
                    onChange={handleProgressInputChange}
                    list="appsDatalist"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="appsDatalist"></datalist>
                </label>
                </div>
                </div>
                <div className="form-row">
              <div className="input-group">
                <label>
                  App Published Date:
                  <input
                    type="date"
                    name="gameappPublishedDate"
                    value={progressFormData.gameappPublishedDate}
                    onChange={handleProgressInputChange}
                  />
                </label>
              </div>
            </div>
            





            <div className="form-row">
  <div className="input-group">
    <label>
      Total Download 
      <input
        type="text"
        name="gameTotalDownload1"
        value={progressFormData.gameTotalDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="gameMonthlyDownload1"
        value={progressFormData.gameMonthlyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download       
      <input
        type="text"
        name="gameDailyDownload1"
        value={progressFormData.gameDailyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download     
      <input
        type="text"
        name="gameTotalDownload2"
        value={progressFormData.gameTotalDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload2"
        value={progressFormData.gameMonthlyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload2"
        value={progressFormData.gameDailyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload3"
        value={progressFormData.gameTotalDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload3"
        value={progressFormData.gameMonthlyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload3"
        value={progressFormData.gameDailyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload4"
        value={progressFormData.gameTotalDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload4"
        value={progressFormData.gameMonthlyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload4"
        value={progressFormData.gameDailyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload5"
        value={progressFormData.gameTotalDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload5"
        value={progressFormData.gameMonthlyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload5"
        value={progressFormData.gameDailyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload6"
        value={progressFormData.gameTotalDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="gameMonthlyDownload6"
        value={progressFormData.gameMonthlyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload6"
        value={progressFormData.gameDailyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download       
      <input
        type="text"
        name="gameTotalDownload7"
        value={progressFormData.gameTotalDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="gameMonthlyDownload7"
        value={progressFormData.gameMonthlyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload7"
        value={progressFormData.gameDailyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload8"
        value={progressFormData.gameTotalDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload8"
        value={progressFormData.gameMonthlyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload8"
        value={progressFormData.gameDailyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload9"
        value={progressFormData.gameTotalDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload9"
        value={progressFormData.gameMonthlyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload9"
        value={progressFormData.gameDailyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload10"
        value={progressFormData.gameTotalDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload10"
        value={progressFormData.gameMonthlyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload10"
        value={progressFormData.gameDailyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  </div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload11"
        value={progressFormData.gameTotalDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload11"
        value={progressFormData.gameMonthlyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload11"
        value={progressFormData.gameDailyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="gameTotalDownload12"
        value={progressFormData.gameTotalDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="gameMonthlyDownload12"
        value={progressFormData.gameMonthlyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="gameDailyDownload12"
        value={progressFormData.gameDailyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>





               <div className="form-row">
                <div className="input-group">
                <label>
                  iOS Console:
                  <input
                    name="iOSAccount"
                    value={progressFormData.iOSAccount}
                    onChange={handleProgressInputChange}
                    list="iOSAccountList"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="iOSAccountList">
                    <option value="">Select an iOS Account</option>
                    <option value="Adnan Haider (ios Account)">Adnan Haider (ios Account)</option>
                    <option value="Syed Kamran Haider (ios Account)">
                      Syed Kamran Haider (ios Account)
                    </option>
                  </datalist>
                </label>
                </div>
                </div> 
                <div className="form-row">
                <div className="input-group">      
                <label>
                  Apps:
                  <input
                    name="iOSApps"
                    value={progressFormData.iOSApps}
                    onChange={handleProgressInputChange}
                    list="appsDatalist"
                    style={{ fontSize: "16px" }}
                  />
                  <datalist id="appsDatalist"></datalist>
                </label>
                </div>
                </div> 
                <div className="form-row">
              <div className="input-group">
                <label>
                  App Published Date:
                  <input
                    type="date"
                    name="iOSappPublishedDate"
                    value={progressFormData.iOSappPublishedDate}
                    onChange={handleProgressInputChange}
                  />
                </label>
              </div>
            </div>
              




            <div className="form-row">
  <div className="input-group">
    <label>
      Total Download 
      <input
        type="text"
        name="iOSTotalDownload1"
        value={progressFormData.iOSTotalDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="iOSMonthlyDownload1"
        value={progressFormData.iOSMonthlyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download       
      <input
        type="text"
        name="iOSDailyDownload1"
        value={progressFormData.iOSDailyDownload1}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download     
      <input
        type="text"
        name="iOSTotalDownload2"
        value={progressFormData.iOSTotalDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload2"
        value={progressFormData.iOSMonthlyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload2"
        value={progressFormData.iOSDailyDownload2}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload3"
        value={progressFormData.iOSTotalDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload3"
        value={progressFormData.iOSMonthlyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload3"
        value={progressFormData.iOSDailyDownload3}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload4"
        value={progressFormData.iOSTotalDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload4"
        value={progressFormData.iOSMonthlyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload4"
        value={progressFormData.iOSDailyDownload4}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload5"
        value={progressFormData.iOSTotalDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload5"
        value={progressFormData.iOSMonthlyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload5"
        value={progressFormData.iOSDailyDownload5}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload6"
        value={progressFormData.iOSTotalDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="iOSMonthlyDownload6"
        value={progressFormData.iOSMonthlyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload6"
        value={progressFormData.iOSDailyDownload6}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download       
      <input
        type="text"
        name="iOSTotalDownload7"
        value={progressFormData.iOSTotalDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download       
      <input
        type="text"
        name="iOSMonthlyDownload7"
        value={progressFormData.iOSMonthlyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload7"
        value={progressFormData.iOSDailyDownload7}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload8"
        value={progressFormData.iOSTotalDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload8"
        value={progressFormData.iOSMonthlyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload8"
        value={progressFormData.iOSDailyDownload8}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload9"
        value={progressFormData.iOSTotalDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload9"
        value={progressFormData.iOSMonthlyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload9"
        value={progressFormData.iOSDailyDownload9}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>

<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload10"
        value={progressFormData.iOSTotalDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload10"
        value={progressFormData.iOSMonthlyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload10"
        value={progressFormData.iOSDailyDownload10}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  </div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload11"
        value={progressFormData.iOSTotalDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload11"
        value={progressFormData.iOSMonthlyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload11"
        value={progressFormData.iOSDailyDownload11}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>
  
<div className="form-row">
  <div className="input-group">
    <label>
      Total Download        
      <input
        type="text"
        name="iOSTotalDownload12"
        value={progressFormData.iOSTotalDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Monthly Download        
      <input
        type="text"
        name="iOSMonthlyDownload12"
        value={progressFormData.iOSMonthlyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
  <div className="input-group">
    <label>
      Daily Download        
      <input
        type="text"
        name="iOSDailyDownload12"
        value={progressFormData.iOSDailyDownload12}
        onChange={handleProgressInputChange}
      />
    </label>
  </div>
</div>


            <div className="form-row">
            <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Progress;




