import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./EditForm.css";

const EditForm = ({ id , data, onSubmit, setIsEditing, userType, userId, currentProfilePicPath }) => {
  const [editedData, setEditedData] = useState(data);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const isOwnProfile = data.userId === userId;
  const isAdmin = userType === "admin";

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePicFile(file);
  };

  const handleSubmit = async () => {
    confirmAlert({
      title: "Confirm Update",
      message: <div><p>Are you sure you want to update this record?</p></div>,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const formData = new FormData();
              if (profilePicFile) {
                formData.append("profilePic", profilePicFile);
              }
              formData.append("data", JSON.stringify(editedData));

              const response = await fetch(`http://65.0.12.194:3000/employee/${id}`, {
                method: 'PUT',
                body: formData,
              });

              if (response.ok) {
                const updatedEmployee = await response.json();
                onSubmit(updatedEmployee);
                setIsEditing(false);
              } else {
                console.error('Failed to update employee:', response.statusText);
              }
            } catch (error) {
              console.error('Error updating employee:', error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    setProfilePicFile(null);
  };
  return (
    <div className="modal-overlay">
      <div className="editFormWrapper">
        
        <form onSubmit={(e) => e.preventDefault()}>
        <label>
            Profile Picture
            <input
              type="file"
              name="profilePic"
              onChange={(e) => handleFileChange(e)}
            />
          </label>

          <div className="current-profile-pic">
             {profilePicFile && (
              <img
              src={profilePicFile ? URL.createObjectURL(profilePicFile) : `http://65.0.12.194:3000/${currentProfilePicPath}`}              
                alt="New Profile Pic"
                className="profile-pic"
              />
            )}
          </div>
              <label> ID<span className="required">*</span>
              <input
                type="text"
                name="id"
                value={editedData.id}
                disabled={!isAdmin} 
                onChange={handleChange}
                required
              />
            </label>
            <label>
  Status
  <select
    name="status"
    value={editedData.status}
    disabled={!isAdmin} 
    onChange={handleChange}
    required
  >
    <option value="">Select Status</option>
    <option value="Active">Active</option>
    <option value="Former">Former</option>
    <option value="Transfered">Transfered</option>
  </select>
</label>

          <label>
            
          Full Name<span className="required">*</span>
            <input
              type="text"
              name="fullName"
              value={editedData.fullName}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
          Remarks<span className="required">*</span>
            <input
              type="text"
              name="remarks"
              value={editedData.remarks}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
          Email<span className="required">*</span>
            <input
              type="email"
              name="email"
              value={editedData.email}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Date of Birth
            <input
              type="date"
              name="dob"
              value={editedData.dob}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Joining Date
            <input
              type="date"
              name="joining"
              value={editedData.joining}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Leaving Date
            <input
              type="date"
              name="leavingDate"
              value={editedData.leavingDate}
              disabled={!isAdmin} 
              onChange={handleChange}
            />
          </label>
          <label>
          Department
            <input
              type="text"
              name= "department"
              value={editedData.department}
              disabled={!isAdmin} 
              onChange={handleChange}
            />
          </label>
          <label>
            Job Title
            <input
              type="text"
              name="tittle"
              value={editedData.tittle}
              disabled={!isAdmin} 
              onChange={handleChange}
            />
          </label>
          <label>
  Bank
  <select
    name="bankname"
    value={editedData.bankname}
    disabled={!isAdmin} 
    onChange={handleChange}
    required
  >
     <option value="Allied Bank Limited">Allied Bank Limited</option>
                <option value="Askari Bank">Askari Bank </option>
                <option  value="Al Baraka Bank">Al Baraka Bank</option>
                <option  value="Bank of Punjab">Bank of Punjab</option>
                <option  value="Bank Alfalah Limited">Bank Alfalah Limited</option>
                <option  value="Bank AL Habib Limited">Bank AL Habib Limited</option>
                <option  value="Bank of Khyber">Bank of Khyber</option>
                <option  value="Bank Alfalah">Bank Alfalah</option>
                <option  value="Dubai Bank">Dubai Bank</option>
                <option  value="Dubai Islamic Bank">Dubai Islamic Bank</option>
                <option  value="Faysal Bank Limited">Faysal Bank Limited</option>
                <option  value="Habib Bank Limited">Habib Bank Limited</option>
                <option  value="JS Bank Limited">JS Bank Limited</option>
                <option  value="MCB Bank Limited">MCB Bank Limited</option>
                <option  value="Meezan Bank Limited">Meezan Bank Limited</option>
                <option  value="National Bank of Pakistan">National Bank of Pakistan</option>
                <option  value="Standard Chartered Bank">Standard Chartered Bank</option>
                <option  value="Soneri Bank">Soneri Bank</option>
                <option value="United Bank Limited">United Bank Limited</option>
  </select>
</label>
          <label>
            Account Number
            <input
              type="text"
              name="account"
              value={editedData.account}
              disabled={!isAdmin} 
              onChange={handleChange}
            />
          </label>
          <label>
            Salary
            <input
              type="number"
              name="salary"
              value={editedData.salary}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
        
          <label>
            Contact<span className="required">*</span>

            <input
              type="text"
              name="contact"
              value={editedData.contact}
              disabled={!isAdmin} 
              onChange={handleChange}
            />
          </label>
          <label>
            City
            <input              
              name="addressCity"
              value={editedData.addressCity}
              disabled={!isAdmin} 
              onChange={handleChange}
              list="addressCityList"
              required
              />
              <datalist id="addressCityList">
              <option value="Abbottabad">Abbottabad</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Islamabad">Islamabad</option>
              <option value="Karachi">Karachi</option>
              <option value="Lahore">Lahore</option>
              <option value="Multan">Multan</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Rawalpindi">Rawalpindi</option>
              </datalist>
          </label>

          {/* <label>
            Postal Code
            <input
              type="text"
              name="addressPostalCode"
              value={editedData.addressPostalCode}
              onChange={handleChange}
            />
          </label> */}
          <label>
            CNIC<span className="required">*</span>

            <input
              type="text"
              name="cnic"
              value={editedData.cnic}
              disabled={!isAdmin} 
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Gender
            <input
              name="gender"
              value={editedData.gender}
              disabled={!isAdmin} 
              onChange={handleChange}
              list="genderList"
              required
            />
              <datalist id="genderList">
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              </datalist>
          </label>
          {/* <br/> */}
          <div className="button-group">
          <button type="button" className="update-button" onClick={handleSubmit}>
              Update
            </button>
            <button type="button" className="cancel-button" onClick={() => setIsEditing(false)}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default EditForm;