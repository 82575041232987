import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import "./editForm.css";

// Importing bank logos
import unitedBankLogo from "./images/ubl.png";
import albLogo from "./images/alb.png";
import baLogo from "./images/alf.png";
import askLogo from "./images/ask.png";
import albbLogo from "./images/Albb.png";
import bobLogo from "./images/Bop.png";
import balLogo from "./images/bil.png";
import HbLLogo from "./images/Hbl (2).png";
import BkLogo from "./images/Bok.png";
import DbLogo from "./images/db (2).png";
import DubaiLogo from "./images/dubai.PNG";
import FbLogo from "./images/Fb.png";
import HbLogo from "./images/Hb.jpg";
import jsLogo from "./images/js.png";
import McbLogo from "./images/mcb.png";
import MeezanLogo from "./images/meezan.jpeg";
import NbLogo from "./images/Nb.png";
import stdLogo from "./images/std.png";
import sobLogo from "./images/sob.png";

const EditForm = ({ isOpen, onClose, record, onSave }) => {
  const [formData, setFormData] = useState({ ...record });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`http://65.0.12.194:3000/bank/${record._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedRecord = await response.json();
      onSave(updatedRecord);
      onClose(); 
    } catch (error) {
      console.error("Error updating bank record:", error);
    }
  };

  const bankImages = {
    'United Bank Limited': unitedBankLogo,
    'Allied Bank Limited': albLogo,
    'Bank Alfalah': baLogo,
    'Askari Bank': askLogo,
    'Al Baraka Bank': albbLogo,
    'Bank of Punjab': bobLogo,
    'Bank Alfalah Limited': balLogo,
    'Bank AL Habib Limited': HbLLogo,
    'Bank of Khyber': BkLogo,
    'Dubai Islamic Bank': DbLogo,
    'Dubai Bank': DubaiLogo,
    'Faysal Bank Limited': FbLogo,
    'Habib Bank Limited': HbLogo,
    'JS Bank Limited': jsLogo,
    'MCB Bank Limited': McbLogo,
    'Meezan Bank Limited': MeezanLogo,
    'National Bank of Pakistan': NbLogo,
    'Standard Chartered Bank': stdLogo,
    'Soneri Bank': sobLogo,
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="modal-container">
        <h1 id="modal-title">Edit Bank Account</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="bank">Bank Name</label>
            <input
              id="bank"
              name="bank"
              value={formData.bank}
              onChange={handleChange}
              list="bankList"
              required
            />
            <datalist id="bankList">
              {Object.keys(bankImages).map((bank) => (
                <option key={bank} value={bank} />
              ))}
            </datalist>
            {formData.bank && (
              <img
                src={bankImages[formData.bank]}
                alt={`${formData.bank} Logo`}
                className="bank-logo"
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="google">Google Account</label>
            <input
              id="google"
              name="google"
              value={formData.google}
              onChange={handleChange}
              list="googleList"
              required
            />
            <datalist id="googleList">
              <option value="Appstark Tech" />
              <option value="D-apps Studio" />
              <option value="Adnan Haider (ios)" />
              <option value="Syed Kamran Haider (ios)" />
              <option value="Nanfa Studio" />
            </datalist>
          </div>

          <div className="form-group">
            <label htmlFor="accountNumber">Account Number</label>
            <input
              id="accountNumber"
              type="text"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="accountTitle">Account Title</label>
            <input
              id="accountTitle"
              type="text"
              name="accountTitle"
              value={formData.accountTitle}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="bankAddress">Bank Address</label>
            <input
              id="bankAddress"
              type="text"
              name="bankAddress"
              value={formData.bankAddress}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="card">Card</label>
            <input
              id="card"
              type="text"
              name="card"
              value={formData.card}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="expiryDate">Expiry Date</label>
            <input
              id="expiryDate"
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="cvv">CVV</label>
            <input
              id="cvv"
              type="text"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
            />
          </div>

          <div className="buttons-containers">
            <button type="submit" className="button">
              Update
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditForm;
