import React, { PureComponent } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { Box, Typography } from "@mui/material";
import './Semi.css';

class Example extends PureComponent {
  renderTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tooltip">
          <p style={{ color: payload[0].payload.color }}>{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  render() {
    const { activeCount, formerCount, transferedCount } = this.props;

    const data = [
      { name: 'Active', value: activeCount, color: 'green' },
      { name: 'Former', value: formerCount, color: '#FF0000' },
      { name: 'Transfered', value: transferedCount, color: '#3498db' }
    ];

    const totalEmployees = activeCount + formerCount + transferedCount;

    return (
      <Box style={{ position: "relative", overflow: "hidden" }}>
        <PieChart width={320} height={150}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            startAngle={180}
            endAngle={0}
            cx="50%"
            cy="100%"
            innerRadius={80}
            outerRadius={110}
            fill="#C0C0C0"
            stroke="none"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={this.renderTooltip} />
        </PieChart>

        <Box style={textStyle}>
          <Typography variant="h5" fontWeight={500} color="primary">
            {totalEmployees}
          </Typography>
          <Typography variant="body1" fontSize={15} color={"gray"}>
            Employee{totalEmployees !== 1 ? "s" : ""}
          </Typography>
        </Box>
      </Box>
    );
  }
}

const textStyle = {
  position: "absolute",
  top: "70%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "99",
  textAlign: "center",
};

export default Example;
