import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { useProgressContext } from "../../ProgressContext";
import axios from "axios";
import "./ProgressView.css";
const ProgressView = () => {
  const { progressDetails, setProgressDetails } = useProgressContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [googleProgress, setGoogleProgress] = useState([]);
  const [gameProgress, setGameProgress] = useState([]);
  const [iOSProgress, setIOSProgress] = useState([]);

  useEffect(() => {
    fetchAllProgress();
  }, []);

  const fetchAllProgress = async () => {
    try {
      const response = await axios.get("http://65.0.12.194:3000/progress");
      const progressData = response.data;
      setProgressDetails(progressData);
      setGoogleProgress(progressData.filter(detail => detail.googleAccount));
      setGameProgress(progressData.filter(detail => detail.gameAccount));
      setIOSProgress(progressData.filter(detail => detail.iOSAccount));
    } catch (error) {
      setError("No Internet!");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSuccess = () => {
    // setSuccessMessage("Changes saved successfully!");
    // setTimeout(() => {
    //   setSuccessMessage(null);
    // }, 3000);
    fetchAllProgress(); 
  };

  if (loading) return <CircularProgress />;
  if (error) return <p style={{ fontWeight: "bold", color: "red" }}>{error}</p>;

  return (
    <div>
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
      <div className="headeers">
        <h1>Apps Progress View</h1>
      </div>

      <div>
        <h2> Apps Progress (Till Date)</h2>
        <DataTable progressData={googleProgress} onSaveSuccess={handleSaveSuccess} />
      </div>

      <div>
        <h2>Game Apps ActionShore Account (Jan23-Jan-24)</h2>
        <DataTable progressData={gameProgress} onSaveSuccess={handleSaveSuccess} />
      </div>

      <div>
        <h2>(iOS Apps) Progress till Nov22-Jan-24</h2>
        <DataTable progressData={iOSProgress} onSaveSuccess={handleSaveSuccess} hideTotalUserLoss />
      </div>
    </div>
  );
};

const DataTable = ({ progressData, onSaveSuccess, hideTotalUserLoss }) => {
  const [editedData, setEditedData] = useState({});
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, progressData.length);
  }, [progressData]);

  const handleSave = async (index) => {
    if (progressData[index]) {
      const progressId = progressData[index]._id;
      try {
        await axios.put(`http://65.0.12.194:3000/progress/${progressId}`, editedData[index]);
        onSaveSuccess();
      } catch (error) {
        console.error("Error updating progress:", error);
      }
    } else {
      console.error("Error: progressData[index] is undefined.");
    }
  };

  const handleInputChange = (e, key, id) => {
    const { value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [key]: value,
      },
    }));
    handleSave(id);
  };

  const handleKeyDown = (e, rowIndex, colIndex) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextColIndex = colIndex === 39 ? 1 : colIndex + 0; 
      if (nextColIndex < 39) { 
        const nextInput = inputRefs.current[rowIndex][nextColIndex];
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button onClick={() => handleSave(0)} style={{ marginBottom: '10px' }}>
          Save
        </button>
      </div>
      <div className="table-cont">
      <table className="stylesd-tablesss">
        <thead>
        <tr>
            <th className="th" rowspan="2">S.No</th>
            <th className="th" rowSpan="2">Account Name</th>
            <th className="th" rowSpan="2">Apps Name</th>
            <th className="th" rowSpan="2">App Published Date</th>
            <th colspan="3">Jan-2024</th>
            <th colspan="3">Feb-2024</th>
            <th colspan="3">Mar-2024</th>
            <th colspan="3">Apr-2024</th>
            <th colspan="3">May-2024</th>
            <th colspan="3">Jun-2024</th>
            <th colspan="3">Jul-2024</th>
            <th colspan="3">Aug-2024</th>
            <th colspan="3">Sep-2024</th>
            <th colspan="3">Oct-2024</th>
            <th colspan="3">Nov-2024</th>
            <th colspan="3">Dec-2024</th>


        </tr>
        <tr>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  
  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>


  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>
  
  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

</tr>


        </thead>
        <tbody>
          {progressData.map((detail, rowIndex) => (
              <React.Fragment key={detail._id}>
               {rowIndex === 7 && (
  <>
    <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
      <th className="th" rowSpan="2">S.No</th>
      <th className="th" rowSpan="2">Account Name</th>
      <th className="th" rowSpan="2">Apps Name</th>
      <th className="th" rowSpan="2">App Published Date</th>
      <th colSpan="3">Jan-2024</th>
      <th colSpan="3">Feb-2024</th>
      <th colSpan="3">Mar-2024</th>
      <th colSpan="3">Apr-2024</th>
      <th colSpan="3">May-2024</th>
      <th colSpan="3">Jun-2024</th>
      <th colSpan="3">Jul-2024</th>
      <th colSpan="3">Aug-2024</th>
      <th colSpan="3">Sep-2024</th>
      <th colSpan="3">Oct-2024</th>
      <th colSpan="3">Nov-2024</th>
      <th colSpan="3">Dec-2024</th>
    </tr>
    <tr>
    <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  
  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>


  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>
  
  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>

  <th style={{ backgroundColor: '#fad7a0' , color: 'black' , fontWeight: 'bold'}}>Total Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Monthly Download</th>
  <th style={{ backgroundColor: '#fad7a0' , color: 'black', fontWeight: 'bold'}}>Daily Download</th>
    </tr>
  </>
)}
            <tr key={`dataRow${detail._id}`}>
              <td>{rowIndex + 1}</td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleAccount || editedData[rowIndex]?.gameAccount || editedData[rowIndex]?.iOSAccount || detail.googleAccount || detail.gameAccount || detail.iOSAccount || ""}
                  onChange={(e) => handleInputChange(e, "googleAccount", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 1)}
                />
              </td>
              <td>
  <input
    style={{
      textAlign: 'left',
      width: '200px', 
      height: '30px', 
      padding: '5px', 
      fontSize: '16px', 
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxSizing: 'border-box' 
    }}
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleApps || editedData[rowIndex]?.gameApps || editedData[rowIndex]?.iOSApps || detail.googleApps || detail.gameApps || detail.iOSApps || ""}
    onChange={(e) => handleInputChange(e, "googleApps", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 2)}
  />
</td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleappPublishedDate || editedData[rowIndex]?.gameappPublishedDate || editedData[rowIndex]?.iOSappPublishedDate || detail.googleappPublishedDate || detail.gameappPublishedDate || detail.iOSappPublishedDate || ""}
                  onChange={(e) => handleInputChange(e, "googleappPublishedDate", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 3)}
                />
              </td>
              <td>
              <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleTotalDownload1 || editedData[rowIndex]?.gameTotalDownload1 || editedData[rowIndex]?.iOSTotalDownload1 || detail.googleTotalDownload1 || detail.gameTotalDownload1 || detail.iOSTotalDownload1 || ""}
                  onChange={(e) => handleInputChange(e, "googleTotalDownload1", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 4)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleMonthlyDownload1 || editedData[rowIndex]?.gameMonthlyDownload1 || editedData[rowIndex]?.iOSMonthlyDownload1 || detail.googleMonthlyDownload1 || detail.gameMonthlyDownload1 || detail.iOSMonthlyDownload1 || ""}
                  onChange={(e) => handleInputChange(e, "googleMonthlyDownload1", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 5)}
                />
              </td>
              <td>
                <input 
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleDailyDownload1 || editedData[rowIndex]?.gameDailyDownload1 || editedData[rowIndex]?.iOSDailyDownload1 || detail.googleDailyDownload1 || detail.gameDailyDownload1 || detail.iOSDailyDownload1 || ""}
                  onChange={(e) => handleInputChange(e, "googleDailyDownload1", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 6)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleTotalDownload2 || editedData[rowIndex]?.gameTotalDownload2 || editedData[rowIndex]?.iOSTotalDownload2 || detail.googleTotalDownload2 || detail.gameTotalDownload2 || detail.iOSTotalDownload2 || ""}
                  onChange={(e) => handleInputChange(e, "googleTotalDownload2", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 7)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleMonthlyDownload2 || editedData[rowIndex]?.gameMonthlyDownload2 || editedData[rowIndex]?.iOSMonthlyDownload2 || detail.googleMonthlyDownload2 || detail.gameMonthlyDownload2 || detail.iOSMonthlyDownload2 || ""}
                  onChange={(e) => handleInputChange(e, "googleMonthlyDownload2", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 8)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleDailyDownload2 || editedData[rowIndex]?.gameDailyDownload2 || editedData[rowIndex]?.iOSDailyDownload2 || detail.googleDailyDownload2 || detail.gameDailyDownload2 || detail.iOSDailyDownload2 || ""}
                  onChange={(e) => handleInputChange(e, "googleDailyDownload2", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 9)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleTotalDownload3 || editedData[rowIndex]?.gameTotalDownload3 || editedData[rowIndex]?.iOSTotalDownload3 || detail.googleTotalDownload3 || detail.gameTotalDownload3 || detail.iOSTotalDownload3 || ""}
                  onChange={(e) => handleInputChange(e, "googleTotalDownload3", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 10)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleMonthlyDownload3 || editedData[rowIndex]?.gameMonthlyDownload3 || editedData[rowIndex]?.iOSMonthlyDownload3 || detail.googleMonthlyDownload3 || detail.gameMonthlyDownload3 || detail.iOSMonthlyDownload3 || ""}
                  onChange={(e) => handleInputChange(e, "googleMonthlyDownload3", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 11)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.googleDailyDownload3 || editedData[rowIndex]?.gameDailyDownload3 || editedData[rowIndex]?.iOSDailyDownload3 || detail.googleDailyDownload3 || detail.gameDailyDownload3 || detail.iOSDailyDownload3 || ""}
                  onChange={(e) => handleInputChange(e, "googleDailyDownload3", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 12)}
                />
              </td>

              <td>
  <input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload4 || editedData[rowIndex]?.gameTotalDownload4 || editedData[rowIndex]?.iOSTotalDownload4 || detail.googleTotalDownload4 || detail.gameTotalDownload4 || detail.iOSTotalDownload4 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload4", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 13)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload4 || editedData[rowIndex]?.gameMonthlyDownload4 || editedData[rowIndex]?.iOSMonthlyDownload4 || detail.googleMonthlyDownload4 || detail.gameMonthlyDownload4 || detail.iOSMonthlyDownload4 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload4", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 14)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload4 || editedData[rowIndex]?.gameDailyDownload4 || editedData[rowIndex]?.iOSDailyDownload4 || detail.googleDailyDownload4 || detail.gameDailyDownload4 || detail.iOSDailyDownload4 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload4", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 15)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload5 || editedData[rowIndex]?.gameTotalDownload5 || editedData[rowIndex]?.iOSTotalDownload5 || detail.googleTotalDownload5 || detail.gameTotalDownload5 || detail.iOSTotalDownload5 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload5", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 16)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload5 || editedData[rowIndex]?.gameMonthlyDownload5 || editedData[rowIndex]?.iOSMonthlyDownload5 || detail.googleMonthlyDownload5 || detail.gameMonthlyDownload5 || detail.iOSMonthlyDownload5 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload5", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 17)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload5 || editedData[rowIndex]?.gameDailyDownload5 || editedData[rowIndex]?.iOSDailyDownload5 || detail.googleDailyDownload5 || detail.gameDailyDownload5 || detail.iOSDailyDownload5 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload5", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 18)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload6 || editedData[rowIndex]?.gameTotalDownload6 || editedData[rowIndex]?.iOSTotalDownload6 || detail.googleTotalDownload6 || detail.gameTotalDownload6 || detail.iOSTotalDownload6 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload6", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 19)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload6 || editedData[rowIndex]?.gameMonthlyDownload6 || editedData[rowIndex]?.iOSMonthlyDownload6 || detail.googleMonthlyDownload6 || detail.gameMonthlyDownload6 || detail.iOSMonthlyDownload6 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload6", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 20)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload6 || editedData[rowIndex]?.gameDailyDownload6 || editedData[rowIndex]?.iOSDailyDownload6 || detail.googleDailyDownload6 || detail.gameDailyDownload6 || detail.iOSDailyDownload6 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload6", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 21)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload7 || editedData[rowIndex]?.gameTotalDownload7 || editedData[rowIndex]?.iOSTotalDownload7 || detail.googleTotalDownload7 || detail.gameTotalDownload7 || detail.iOSTotalDownload7 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload7", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 22)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload7 || editedData[rowIndex]?.gameMonthlyDownload7 || editedData[rowIndex]?.iOSMonthlyDownload7 || detail.googleMonthlyDownload7 || detail.gameMonthlyDownload7 || detail.iOSMonthlyDownload7 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload7", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 23)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload7 || editedData[rowIndex]?.gameDailyDownload7 || editedData[rowIndex]?.iOSDailyDownload7 || detail.googleDailyDownload7 || detail.gameDailyDownload7 || detail.iOSDailyDownload7 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload7", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 24)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload8 || editedData[rowIndex]?.gameTotalDownload8 || editedData[rowIndex]?.iOSTotalDownload8 || detail.googleTotalDownload8 || detail.gameTotalDownload8 || detail.iOSTotalDownload8 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload8", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 25)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload8 || editedData[rowIndex]?.gameMonthlyDownload8 || editedData[rowIndex]?.iOSMonthlyDownload8 || detail.googleMonthlyDownload8 || detail.gameMonthlyDownload8 || detail.iOSMonthlyDownload8 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload8", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 26)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload8 || editedData[rowIndex]?.gameDailyDownload8 || editedData[rowIndex]?.iOSDailyDownload8 || detail.googleDailyDownload8 || detail.gameDailyDownload8 || detail.iOSDailyDownload8 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload8", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 27)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload9 || editedData[rowIndex]?.gameTotalDownload9 || editedData[rowIndex]?.iOSTotalDownload9 || detail.googleTotalDownload9 || detail.gameTotalDownload9 || detail.iOSTotalDownload9 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload9", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 28)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload9 || editedData[rowIndex]?.gameMonthlyDownload9 || editedData[rowIndex]?.iOSMonthlyDownload9 || detail.googleMonthlyDownload9 || detail.gameMonthlyDownload9 || detail.iOSMonthlyDownload9 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload9", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 29)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload9 || editedData[rowIndex]?.gameDailyDownload9 || editedData[rowIndex]?.iOSDailyDownload9 || detail.googleDailyDownload9 || detail.gameDailyDownload9 || detail.iOSDailyDownload9 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload9", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 30)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload10 || editedData[rowIndex]?.gameTotalDownload10 || editedData[rowIndex]?.iOSTotalDownload10 || detail.googleTotalDownload10 || detail.gameTotalDownload10 || detail.iOSTotalDownload10 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload10", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 31)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload10 || editedData[rowIndex]?.gameMonthlyDownload10 || editedData[rowIndex]?.iOSMonthlyDownload10 || detail.googleMonthlyDownload10 || detail.gameMonthlyDownload10 || detail.iOSMonthlyDownload10 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload10", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 32)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload10 || editedData[rowIndex]?.gameDailyDownload10 || editedData[rowIndex]?.iOSDailyDownload10 || detail.googleDailyDownload10 || detail.gameDailyDownload10 || detail.iOSDailyDownload10 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload10", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 33)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload11 || editedData[rowIndex]?.gameTotalDownload11 || editedData[rowIndex]?.iOSTotalDownload11 || detail.googleTotalDownload11 || detail.gameTotalDownload11 || detail.iOSTotalDownload11 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload11", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 34)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload11 || editedData[rowIndex]?.gameMonthlyDownload11 || editedData[rowIndex]?.iOSMonthlyDownload11 || detail.googleMonthlyDownload11 || detail.gameMonthlyDownload11 || detail.iOSMonthlyDownload11 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload11", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 35)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload11 || editedData[rowIndex]?.gameDailyDownload11 || editedData[rowIndex]?.iOSDailyDownload11 || detail.googleDailyDownload11 || detail.gameDailyDownload11 || detail.iOSDailyDownload11 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload11", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 36)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleTotalDownload12 || editedData[rowIndex]?.gameTotalDownload12 || editedData[rowIndex]?.iOSTotalDownload12 || detail.googleTotalDownload12 || detail.gameTotalDownload12 || detail.iOSTotalDownload12 || ""}
    onChange={(e) => handleInputChange(e, "googleTotalDownload12", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 37)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleMonthlyDownload12 || editedData[rowIndex]?.gameMonthlyDownload12 || editedData[rowIndex]?.iOSMonthlyDownload12 || detail.googleMonthlyDownload12 || detail.gameMonthlyDownload12 || detail.iOSMonthlyDownload12 || ""}
    onChange={(e) => handleInputChange(e, "googleMonthlyDownload12", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 38)}
  />
</td>
<td>
<input
    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
    value={editedData[rowIndex]?.googleDailyDownload12 || editedData[rowIndex]?.gameDailyDownload12 || editedData[rowIndex]?.iOSDailyDownload12 || detail.googleDailyDownload12 || detail.gameDailyDownload12 || detail.iOSDailyDownload12 || ""}
    onChange={(e) => handleInputChange(e, "googleDailyDownload12", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 39)}
  />
</td>

            </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default ProgressView;
